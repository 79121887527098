/* General footer styling */
footer {
    background-color: #1D8996;
    color: white;
    padding: 20px 0;
    overflow-x: hidden; /* Prevents horizontal scrolling */
}

/* Flexbox for proper alignment */
.footer-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start; /* Fixes vertical alignment */
}

/* Mobile Layout - Keep as it is */
@media (max-width: 768px) {
    .footer-row {
        flex-direction: column;
        text-align: left;
    }

    .logo-col,
    .description-col,
    .products-col,
    .contact-col {
        width: 100%;
        text-align: center;
    }
}

/* Desktop Layout - Fixed Overlapping Issue */
@media (min-width: 992px) { 
    .footer-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start; /* Fixes vertical alignment */
    }

    /* LOGO */
    .logo-col {
        flex: 0 0 15%;
        max-width: 15%;
        text-align: left;
    }

    .logo-footer {
        width: 300px;
        height: 200px;
        max-width: 120px; /* Ensure logo doesn't take too much space */
    }

    /* DESCRIPTION */
    .description-col {
        flex: 0 0 30%;
        max-width: 30%;
        text-align: left;
        padding-left: 20px; /* Adds spacing from logo */
    }

    /* PRODUCTS */
    .products-col {
        flex: 0 0 20%;
        max-width: 20%;
        text-align: left;
    }

    /* CONTACT */
    .contact-col {
        flex: 0 0 25%;
        max-width: 25%;
        text-align: left;
    }
}
