.carousel {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
    
   } 

   .heading-small {
    font-size: 44px;
  }
 
  .carousel-inner {
    height: 400px;
  }

  .carousel-item {
    height: 400px;
  } 
  
  input[type="checkbox"] {
    display: none;
  }
  
  @media (max-width: 1000px) {
    nav {
      padding: 0 40px 0 50px;
    }
    .carousel {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 250px;
      
     } 
   
    .carousel-inner {
      height: 250px;
    }
  
    .carousel-item {
      height: 250px;
    } 
  }
  
  @media (max-width: 600px) {
    .heading-small {
      font-size: 10px !important; /* Apply 10px font size for mobile screens */
    }
  }
  
  @media (max-width: 920px) {
    nav .menu-btn i {
      display: block;
    }

    #click:checked ~ .menu-btn i:before {
      content: "\f00d";
    }
  
    #click:checked ~ ul {
      left: 0;
    }
  
    nav ul li {
      width: 100%;
      margin: 40px 0;
    }
  
    nav ul li a {
      width: 100%;
      margin-left: -100%;
      display: block;
      font-size: 20px;
      transition: 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }
  
    #click:checked ~ ul li a {
      margin-left: 0px;
    }
  
    nav ul li a.active,
    nav ul li a:hover {
      background: none;
      color: cyan;
    }
  }
  
  .content {
    position: relative; /* Change to relative to be below nav */
    z-index: 1;
    width: 100%;
    padding: 0 30px;
    color: #1b1b1b;
  }
  
  .content div {
    font-size: 40px;
    font-weight: 700;
  }

  /* Content */
  .carousel-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; /* Ensure it takes full height of the parent */
  }
  
  .carousel-content {
    position: absolute;
    left: 10%; /* Adjust left position as needed */
    top: 50%; /* Center vertically */
    transform: translateY(-50%);
    z-index: 10; /* Ensure it's above the carousel */
    text-align: center;
    color: white; /* Text color */
  }
  
  .carousel-content h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .carousel-content p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .carousel-content button {
    background-color: #1D8996;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .carousel-content button:hover {
    background-color: #156a73;
  }
  
  .carousel-inner {
    position: relative;
  }
  
  .carousel-item {
    height: 100%;
  }
  
  .carousel-item img {
    height: 100%;
    object-fit: cover; /* Ensure images cover the entire space */
  }

  .carousel-content .btn-large {
    font-size: 10px;
    /* padding: 15px 30px; */
  }

  /* media size laptop */
  @media (min-width: 1024px) {
    .carousel-content h2 {
      font-size: 64px;
      margin-bottom: 10px;
    }
    .heading-small {
      font-size: 30px;
    }
    .carousel-content .btn-large {
      font-size: 14px;
      /* padding: 15px 30px; */
    }
    .carousel-content .btn-small {
      display: none;
    }
  }

  /* Text moving below coursel */
  .moving-text-container {
    height: 40px; /* Default height for phones */
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  
  @media (min-width: 768px) {
    .moving-text-container {
      height: 50px; /* Height for laptops */
    }
  }

  @media (max-width: 768px) {
    .moving-text-container {
      height: 50px; /* Height for laptops */
    }
  }

  .moving-text {
    white-space: nowrap;
    position: absolute;
    left: 0; /* Start at the beginning */
    animation: moveText 35s linear infinite; /* Linear animation that repeats infinitely */
  }
  
  @keyframes moveText {
    0% {
      transform: translateX(100%); /* Start off screen */
    }
    100% {
      transform: translateX(-100%); /* Move across the container */
    }
  }
  
  
  