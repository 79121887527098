@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

:root {
  --primary-color: #5344db;
  --accent-color: #5344db;
  --grey: #484848;
  --bg-grey: #efefef;
  --shadow: #949494;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.row {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
}

.col6 {
  width: 48%;
}

.singleProduct {
  width: 100%;
}

.breadcrumb {
  background: #48484810;
  padding: 8px 4px;
  border-radius: 8px;
  font-size: 15px;
}

.breadcrumb span {
  margin-left: 8px;
}

.breadcrumb span:not(:last-child)::after {
  content: '/';
}

.productImageMain {
  height: 480px;
  /* background: var(--bg-grey); */
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.productImageMain img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.productImageSlider {
  display: flex;
  /* gap: 10px; */
  /* margin: 10px 0; */
}

.imageList {
  width: 90px;
  height: 90px;
  /* background: var(--bg-grey); */
  padding: 6px;
  cursor: pointer;
}
.productTitle{
  margin-top: 20px;
  text-align: left;
  font-size: 24px;
  font-weight: 600;
}
.productPrice {
  display: flex;
  align-items: center;
}

.offerPrice {
  font-size: 48px;
  font-weight: 100;
}

.productColor .colorLayout label {
  width: 40px;
  height: 40px;
  margin-top: 10px;
  margin-left: 10px;
  display: inline-block;
  cursor: pointer;
  border-radius: 50%; /* Optional: makes them circles if you haven't already */

}
.productColor h4 {
  display: flex;
  align-items: center;
  gap: 12px;
}
.roseGold {
  background-color: #ffcccc;
}

.whiteGold {
  background-color: #cccccc;
}

.yellowGold {
  background-color: #ffcc66;
}
.productPurity{
  display: flex;
  align-items: center;
  font-size: 24px;  
}
.sizeLayout{
  font-size: 8px;
}
.size{
  padding: 5px;
  background-color: #949494;
  color: white;
  cursor: pointer;
  margin-left: 30px;
  border-radius: 5px;
}
.productBtnGroup {
  display: flex;
  gap: 15px;
}

.buyNow, .addCart, .heart {
  padding: 10px 24px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;
}

.buyNow {
  background-color: var(--accent-color);
  color: #fff;
}

.addCart {
  /* background-color: var(--bg-grey); */
  color: var(--grey);
}

.heart {
  color: var(--grey);
}
.productDetails{

  text-align: left;
}
.productInfoWrapper {
  display: flex;
  flex-wrap: wrap; /* allow items to wrap on smaller screens */
  gap: 12px; /* adds space between the boxes */
}
.productInfoBox{
  display: block;
  padding: 10px;
  width: 180px;
  height: 100px;
  border: 2px black solid;
  border-radius: 10px;
}
.productDetails h5{
  text-align: left;
  background-color: #efe9e9;
  color: black;
  margin-bottom: 20px;
}
.priceBreakup{
  margin-top: 20px;
  text-align: left;
}
.priceBreakup h5{
  text-align: left;
  background-color: #efe9e9;
  color: black;
}
.itemInfo{
  font-size: 12.8px;
}
.ourPromise h5{
  text-align: left;
  background-color: #efe9e9;
  color: black;
}
.ourPromise{
  text-align: left;
  color: #1D8996;
}
.certificateOfAuthenticity{
  
  background-color: #1d8996;
  color: #FCFCCA;
  width: 400px;
  height: 180px;
  padding: 30px;
}

/* RESPONSIVE DESIGN */

/* Tablets & small desktops */
@media screen and (max-width: 1024px) {
  .col6 {
    width: 100%;
  }

  .row {
    flex-direction: column;
    align-items: center;
  }

  .productImageMain {
    height: 400px;
  }

  .imageList {
    width: 80px;
    height: 80px;
  }
  .productTitle {
    font-size: 22px;
    margin-top: 16px;
  }
  .offerPrice {
    font-size: 36px;
  }

  .buyNow, .addCart, .heart {
    width: 100%;
    padding: 12px;
    font-size: 18px;
  }

  .productBtnGroup {
    flex-direction: column;
    width: 100%;
  }
}

/* Mobile devices */
@media screen and (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .breadcrumb {
    font-size: 14px;
  }

  .productImageMain {
    height: 300px;
  }

  .imageList {
    width: 60px;
    height: 60px;
  }
  .productTitle {
    font-size: 20px;
    margin-top: 14px;
  }
  .offerPrice {
    font-size: 28px;
  }

  .buyNow, .addCart, .heart {
    font-size: 16px;
    padding: 10px;
  }

  .productBtnGroup {
    gap: 10px;
  }
}

/* Smallest screens */
@media screen and (max-width: 480px) {
  .productImageMain {
    height: 250px;
  }

  .imageList {
    width: 50px;
    height: 50px;
  }
  .productTitle {
    font-size: 18px;
    margin-top: 12px;
  }
  .offerPrice {
    font-size: 24px;
  }

  .buyNow, .addCart, .heart {
    font-size: 14px;
    padding: 8px;
  }

  .productBtnGroup {
    gap: 8px;
  }
}
