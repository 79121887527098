.transparent-card {
    background-color: transparent;
    border: none;
  }
  
  .card-img-top {
    height: 180px;
    width: 180px;
  }
  
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .future-head {
    margin: 0; /* Reset default margin */
    color:'#1D8996';
    font-family: Times, sans-serif;
  }

  .tsp-button{
    width: 130px;
    padding: 10px 20px;
    background-color: #1D8996;
    color: white;
    border: none;
  }

  @media (max-width: 767px) {
    .card-img-top {
      height: 120px;
      width: 130px;
    }
    .tsp-button{
      font-size: 12px;
      width: 80px;
      padding: 3px 5px;
      background-color: #1D8996;
      color: white;
      border: none;
    }
    .future-head {
      height: 30px;
      margin: 0; /* Reset default margin */
      color:'#1D8996';
      font-family: Times, sans-serif;
    }
  }
  
  /* PHOTO GRID */

  /* Base Styles for the Photo Grid */
.photo-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3px;
  width: 100%;
  height: 85vh;
}

/* Left Column Styles (3 images) */
.left-column .grid-image-container {
  height: 200px; /* Fixed height */
  overflow: visible; /* Allow the image to grow outside of its box */
  position: relative;
  overflow: hidden;
}

/* Right Column Styles (2 images) */
.right-column .grid-image-container {
  height: 300px; /* Fixed height */
  overflow: visible; /* Allow the image to grow outside of its box */
  position: relative;
  overflow: hidden;
}

/* Grid Image Styles */
.grid-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 3s ease-in-out;
  position: absolute; /* Make it grow outside the container */
  top: 0;
  left: 0;

}

/* Animation for zooming in and out */
@keyframes zoom {
  0% {
      transform: scale(1);
  }
  100% {
      transform: scale(1.2); /* Zoom out beyond the container */
  }
}

.grid-image {
  animation: zoom 3s infinite alternate;
  transform-origin: center center;
  overflow: visible; /* Allow zooming beyond bounds */
}

/* Responsive Design for Mobile Screens */
@media (max-width: 768px) {
  .photo-grid {
    height: 65vh;
      grid-template-columns: 1fr 1fr; /* Two columns for the images */
  }

  /* Keep the same layout with the fixed image heights */
  .left-column .grid-image-container {
      height: 200px;
      overflow: hidden;
      padding: 10px;
  }

  .right-column .grid-image-container {
      height: 300px;
      overflow: hidden;
      padding: 10px;
  }
}
