/* Product Slider Container */
.product-slider-container1 {
  margin-top: 20px;
  padding: 20px;
  width: 100%;
  text-align: center;
  /* background-color: #031026; */
  color: black;
}

/* Heading */
.product-slider-container1 h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: black;
}

/* Product Item */
.product-item1 {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center;
  justify-content: flex-start;
  width: 150px; 
  height: auto;
  padding: 10px;
}

/* Product Image */
.product-item1 img {
  height: 160px;
  width: 160px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 5px; /* Space between image and text */
}

/* Product Name */
.product-item1 p {
  display: block; /* Ensures p is a block-level element */
  font-size: 1rem;
  text-align: center;
  margin: 0;
  color: black;
  width: 100%; /* Ensures full width to stay below image */
}

/* Fix for Slick Slider */
.slick-track {
  display: flex !important;
  transition: transform 0s linear !important; /* Prevents jumps */
}

.slick-slide {
  display: flex !important;
  justify-content: center;
  width: auto !important;
}

/* Ensures smooth looping */
.slick-cloned {
  display: block !important;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .product-item1 {
    width: 120px;
  }

  .product-item1 img {
    height: 120px;
    width: 120px;
  }
}

@media screen and (max-width: 480px) {
  .product-slider-container1 {
    height: auto;
    padding: 15px;
  }

  .product-item1 {
    width: 100px;
  }

  .product-item1 img {
    height: 80px;
    width: 80px;
  }

  .product-item1 p {
    font-size: 0.9rem;
  }
}
